<template>
  <div class="competitordownload">
    <div
      class="competitordownload__logo"
      @click="gotToHomePage()"
    >
      <img
        src="./home-assets/img/logo/logo-white.svg"
        alt=""
      >
    </div>
    <div class="competitordownload-content">
      <div class="competitordownload-content__title">
        Thank you <br> for you interest
      </div>
      <div class="competitordownload-content__text btn-competitordownload-download">
        Download competitive analysis chart
      </div>
      <a
        class="competitordownload-content__button"
        download="Comparison Chart for Website, Mobile and TV apps.pdf"
        :href="require('@/assets/files/comparison_chart.pdf')"
        type="application/octet-stream"
      >
        Download chart
      </a>
    </div>
    <div class="competitordownload-footer">
      <div class="competitordownload-footer__text">
        Feel free to schedule a meeting if you have any questions.
      </div>
      <div class="competitordownload-footer__text">
        <a
          href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01"
          target="_blank"
        >
          Schedule a meeting
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Competitordownload',
  mounted() {
    const [preloaderWapper] = document.getElementsByClassName('preloader-wapper');
    preloaderWapper.classList.add('loaded');
    window.fbq('trackCustom', 'Competitive Chart Download');
  },
  methods: {
    gotToHomePage() {
      this.$router.push({ name: 'index' });
    },
  },
};
</script>

<style lang="scss" scoped>

@import './assets/sass/utils/variables';

.competitordownload {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: -24px;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 30px;
  }

  &__logo {
    margin-bottom: 22.4vh;
    display: inline-block;

    @media (max-width: 767px) {
      margin-bottom: 20vh;
    }

  }

}

.competitordownload-content {
  padding: 15px;

  &__title {
    margin-bottom: 31px;
    font: 56px $font-global-bold;
    color: #191927;

    @media (max-width: 767px) {
      font-size: 38px;
    }

  }

  &__text {
    margin-bottom: 31px;
    font: 18px $font-global;
    color: #909090;

    @media (max-width: 767px) {
      font-size: 24px;
    }

  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    height: 48px;
    border-radius: 8px;
    background: $color-turquoise;
    color: $color-white;
    font: 16px $font-global-medium;
    cursor: pointer;
    border: 2px solid $color-turquoise;
    transition: color .2s ease-in, background-color .2s ease-in;
    text-decoration: none;

    &:hover {
      background: $color-white;
      color: $color-turquoise;
    }

  }

}

.competitordownload-footer {
  padding-top: 20px;
  padding-bottom: 80px;
  margin-top: auto;

  &__text {
    font:16px / 28px $font-global;
    color: #909090;

    @media (max-width: 767px) {
      font-size: 13px;
    }

    a {
      color: $color-turquoise;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

    }

  }

}
</style>
<!-- Created by zas on 23.04.2021  -->
